





import Vue from "vue";
import WidgetFrame from '@/components/widgets/WidgetFrame.vue';
import Calendar from '@/components/Calendar.vue';
import moment from 'moment';

export default Vue.extend({
  name: "CalendarWidget",
  components: {
    WidgetFrame,
    Calendar,
  },
  props: [],
  computed: {
    title() {
      return `${moment().format('dddd, MMMM D')} - ${moment().format('wo')} week`;
    },
  },
  data() {
    return {};
  },
  methods: {},
});
