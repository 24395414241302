




































































































import PowerTableWidget from "@/components/widgets/PowerTableWidget.vue";
import HabitsRoutinesWidget from "@/components/widgets/HabitsRoutinesWidget.vue";
import TagsInputWidget from "@/components/widgets/TagsInputWidget.vue";
import TagsMostPopularWidget from "@/components/widgets/TagsMostPopularWidget.vue";
import TagsLatestWidget from "@/components/widgets/TagsLatestWidget.vue";
import CalendarWidget from "@/components/widgets/CalendarWidget.vue";
import MetricsWidget from "@/components/widgets/MetricsWidget.vue";
import MetricsGroupWidget from "@/components/widgets/MetricsGroupWidget.vue";
import MoodInputWidget from "@/components/widgets/MoodInputWidget.vue";
import ActivityInputWidget from "@/components/widgets/ActivityInputWidget.vue";
import Vue from "vue";
import moment from "moment";
import { PowerStorage } from "@/powerStorage";
import { Field, FieldOption } from '@/entities/field';
import NotesWidget from '@/components/widgets/NotesWidget.vue';

export default Vue.extend({
  name: "HomeView",
  components: {
    NotesWidget,
    CalendarWidget,
    PowerTableWidget,
    TagsMostPopularWidget,
    HabitsRoutinesWidget,
    TagsInputWidget,
    // CalendarWidget,
    TagsLatestWidget,
    // MetricsWidget,
    // MoodInputWidget,
    // ActivityInputWidget,
    MetricsGroupWidget,
  },
  data() {
    return {};
  },
  computed: {
    dailyLists() {
      return [
        {
          datasetName: "daily-gratitude",
          title: "What am I grateful for today?",
        },
        {
          datasetName: "daily-proud",
          title: "What am I proud of today?",
        },
        {
          datasetName: "daily-thoughts",
          title: "Daily thoughts",
        },
        {
          datasetName: "daily-learnings",
          title: "What I learnt today?",
        },
        {
          datasetName: "daily-meaningful-moments",
          title: "Today's meaningful moments",
        },
        {
          datasetName: "daily-achievements",
          title: "Today's achievements",
        },
        {
          datasetName: "daily-inspirations",
          title: "What inspired me today?",
        },
        {
          datasetName: "daily-good-things",
          title: "What good happened to me today?",
        },
        {
          datasetName: "daily-family",
          title: "How I spent time with my family?",
        },
        {
          datasetName: "daily-disappointments",
          title: "Today's disappointments",
        },
        {
          datasetName: "daily-confrontations",
          title: "What confrontations I avoid?",
        },
        {
          datasetName: "daily-goals",
          title: "How I moved forward my goals?",
        },
        {
          datasetName: "daily-ideas",
          title: "What new ideas I have?",
        },
        {
          datasetName: "daily-disturbances",
          title: "What disturbs me and ruins my flow?",
        },
        {
          datasetName: "daily-dreams",
          title: "What were my dreams tonight?",
        },
        {
          datasetName: "daily-fucks-given",
          title: "Fucks given today",
        },
        {
          datasetName: "daily-stress",
          title: "What stressed me today?",
        },
      ];
    },
  },
  methods: {
    onlyCreatedToday(row: any) {
      return moment(row._createdAt).isSameOrAfter(moment(), "day");
    },
    onlyTodayAndOverdue(row: any) {
      const todayEod = moment().endOf("day");
      const todaySod = moment().startOf("day");

      if (row.doneAt$) {
        return moment(row.doneAt$).isBetween(todaySod, todayEod, "days", "[]");
      }

      const statusField = PowerStorage.create("action-items").fields.find(
        (f: Field) => f.name == "status"
      );

      const fieldOption = statusField?.options?.find(
        (fo: FieldOption) => fo.value == row.status
      );
      const isDone = fieldOption?.statusGroup == "done";

      if (row.doDate) {
        if (
          isDone &&
          moment(row.doDate).isBetween(todaySod, todayEod, "days", "[]")
        ) {
          return true;
        }
        if (!isDone && moment(row.doDate).isBefore(todayEod)) {
          return true;
        }
      }

      if (row.dueDate) {
        if (
          isDone &&
          moment(row.dueDate).isBetween(todaySod, todayEod, "days", "[]")
        ) {
          return true;
        }
        if (!isDone && moment(row.dueDate).isBefore(todayEod)) {
          return true;
        }
      }

      return false;
    },
  },
});
